
<template>
  <div>
    <v-dialog v-model="dialogRetorno.status" max-width="575" persistent>
      <v-card class="pa-5">
        <v-card-title class="text-h4">
          <span class="mx-auto">{{dialogRetorno.title}}</span>
        </v-card-title>
        <div class="text-center py-3">
          <v-icon size="100" :color="dialogRetorno.iconColor">{{dialogRetorno.icon}}</v-icon>
        </div>
        <v-card-text class="text-h6 text-center">{{dialogRetorno.msg}}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" elevation="0" class="px-10" @click="dialogRetorno.status = false">OK</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" fullscreen persistent>
      <v-card>
        <v-card-title class="primary white--text pl-5 py-4 pr-3">
          {{ idlista ? 'Alteração de lista: '+idlista : 'Nova lista' }}
          <v-spacer />
          <v-btn @click="dialog = false" icon class="mr-1"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card elevation="1" class="mb-1">
          <v-tabs class="d-block d-md-none" v-model="tab" grow light hide-slider>
            <v-tab href="#tab-1" active-class="indigo lighten-5" :disabled="carregando">
              <v-icon>mdi-keyboard-variant</v-icon>
              <span class="ml-2 d-none d-sm-block">Pesquisa Geral</span>
            </v-tab>
            <v-tab href="#tab-2" active-class="indigo lighten-5" :disabled="carregando">
              <v-icon>mdi-numeric</v-icon>
              <span class="ml-2 d-none d-sm-block">Somente códigos</span>
            </v-tab>
            <v-tab href="#tab-3" active-class="indigo lighten-5" :disabled="carregando">
              <v-icon>mdi-camera</v-icon>
              <span class="ml-2 d-none d-sm-block">Leitura via camêra</span>
            </v-tab>
          </v-tabs>
        </v-card>
        <v-card elevation="0" class="mt-0">
          <v-card-text class="pa-0">
            <v-tabs-items v-model="tab" class="rounded">
              <v-tab-item value="tab-1">
                <v-card-subtitle class="py-4 px-5 d-flex d-md-none">
                  <div class="subtitle-1 font-weight-bold">Pesquisa Geral</div>
                  <v-spacer/>
                  <v-badge dot :value="busca.flagestoque?1:0" color="green" offset-x="7" offset-y="5">
                    <v-icon @click="sheet = !sheet">mdi-filter-menu</v-icon>
                  </v-badge>
                </v-card-subtitle>
                <v-card-subtitle class="py-4 px-5 d-md-block d-none">
                  <div class="text-h6 font-weight-bold text-center">Pesquisa Geral</div>
                </v-card-subtitle>
                <v-progress-linear :active="carregando" indeterminate color="primary"></v-progress-linear>
                <v-divider/>
                <v-card-subtitle class="py-4 d-block d-md-none">
                  <v-text-field
                      :disabled="carregando"
                      filled
                      v-model="codigoDeBarras"
                      label="Buscar produtos"
                      hide-details
                      dense
                      class="pb-1"
                      :placeholder="dynamicPlaceholder"
                      append-icon="mdi-close"
                      @click:append="codigoDeBarras=''"
                  ></v-text-field>
                  <v-btn :loading="carregando" width="100%" class="primary mt-3" @click="buscaProduto(1,'P')">Buscar <v-icon class="ml-1">mdi-magnify</v-icon></v-btn>
                </v-card-subtitle>
                <v-card-subtitle class="py-4 d-md-block d-none">
                  <v-row no-gutters class="mr-1">
                    <v-col cols="12">
                      <v-text-field
                          autofocus
                          :disabled="carregando"
                          filled
                          v-model="codigoDeBarras"
                          label="Buscar produtos"
                          hide-details
                          dense
                          class="pb-1"
                          :placeholder="dynamicPlaceholder"
                          append-icon="mdi-close"
                          @click:append="codigoDeBarras=''"
                          @keydown.enter="buscaProduto(1,'G')"
                      >
                        <template v-slot:append>
                          <v-btn class="mt-n1" icon :loading="carregando" @click="codigoDeBarras=''">
                            <v-icon size="26">mdi-close</v-icon>
                          </v-btn>
                          <v-btn class="mt-n1 ml-1" color="primary" icon :loading="carregando" @click="buscaProduto(1,'G')">
                            <v-icon size="26">mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:append-outer>
                          <v-badge offset-x="12" color="green" :content="listaProdutosSelecionados.lista.length" :value="listaProdutosSelecionados.lista.length">
                            <v-btn :disabled="listaProdutosSelecionados.lista.length===0" height="50px" elevation="0" :loading="carregando" class="primary ml-1 mt-n3" @click="dialogSelecionadosPc=true">
                              <v-icon class="mr-2">mdi-eye-outline</v-icon> Ver produtos adicionados a lista
                            </v-btn>
                          </v-badge>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-switch hide-details class="my-1" color="success" v-model="busca.flagestoque" label="Inclui na busca produtos sem estoque"></v-switch>
                </v-card-subtitle>
                <v-divider class="mt-2" />
                <v-card-text class="pa-0 d-md-block d-none">
                  <v-data-table
                      :headers="[
                          { text: 'Cód. Barras', value: 'codigobarra', align:'left', sortable:false, class:'text-truncate'},
                          { text: 'Cód. Produto', value: 'idsubproduto', align:'left', sortable:false, class:'text-truncate'},
                          { text: 'Produto', value: 'produto', align:'left', sortable:false, class:'text-truncate'},
                          { text: 'Emb. Saída', value: 'embalagemsaida', align:'center', sortable:false, class:'text-truncate'},
                          { text: 'Emb. Entrada', value: 'embalagementrada', align:'center', sortable:false, class:'text-truncate'},
                          { text: 'Preço', value: 'preco', align:'center', sortable:false, class:'text-truncate'},
                          { text: 'Qtde Etiquetas', value: 'quantidade', align:'center', sortable:false, class:'text-truncate'},
                      ]"
                            :items="produtos.lista"
                            height="calc(100vh - 300px)"
                            fixed-header
                            v-model="listaProdutosSelecionados.lista"
                            item-key="compositeKey"
                            hide-default-footer
                            :items-per-page="-1"
                            class="pb-2"
                        >
                          <template v-slot:item.quantidade="{ item }">
                            <td width="180px">
                              <v-row no-gutters>
                                <v-col v-if="!listaProdutosSelecionados.lista.find(u => u.compositeKey === item.compositeKey)" class="py-1">
                                  <v-btn
                                      elevation="0"
                                      color="primary"
                                      block
                                      @click="addItem(item)"
                                      :loading="carregando"
                                      :disabled="carregando"
                                      small
                                      rounded
                                  >
                                    <v-icon small class="mr-1">mdi-plus</v-icon> Adicionar
                                  </v-btn>
                                </v-col>
                                <v-col v-else>
                                  <div class="d-flex align-center">
                                    <v-btn icon :disabled="carregando" @click="decrementaQuantidade('detalhes',item)" x-small width="28" height="28" elevation="0" class="red px-0" dark>
                                      <v-icon size="20">mdi-minus</v-icon>
                                    </v-btn>
                                    <v-text-field
                                        hide-details
                                        dense
                                        reverse
                                        readonly
                                        :disabled="carregando"
                                        v-model.number="item.quantidade"
                                        type="number"
                                        @input="verificaQuantidade(item)"
                                        class="ml-2 mr-3"
                                        style="text-align: center !important;"
                                    >
                                    </v-text-field>
                                    <v-btn icon :disabled="carregando" @click="incrementaQuantidade('detalhes',item)" x-small width="28" height="28" elevation="0" class="primary px-0 ml-n1" dark>
                                      <v-icon size="20">mdi-plus</v-icon>
                                    </v-btn>
                                  </div>
                                </v-col>
                              </v-row>
                            </td>
                          </template>
                          <template v-slot:item.codigobarra="{ item }">
                            {{  item.codigobarra }}
                          </template>
                          <template v-slot:item.idsubproduto="{ item }">
                            {{  item.idsubproduto }}
                          </template>
                          <template v-slot:item.produto="{ item }">
                            {{  item.produto }}
                          </template>
                          <template v-slot:item.embalagemsaida="{ item }">
                            <td class="text-center">{{ item.valsaida }} {{  item.embalagemsaida }}</td>
                          </template>
                          <template v-slot:item.embalagementrada="{ item }">
                            <td class="text-center">{{ item.valentrada }} {{  item.embalagementrada }}</td>
                          </template>
                          <template v-slot:item.preco="{ item }">
                            <div style="display: flex; flex-direction: column; align-items: center" v-if="item.promocao">
                              <span class="text-subtitle-2 text-truncate text-decoration-line-through">
                                {{ item.preco | formataDinheiro }}
                              </span>
                              <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip v-bind="attrs" v-on="on" small class="font-weight-bold success mb-2">{{  item.precopromocao | formataDinheiro }}<v-icon x-small color="white" class="ml-1 mt-n1">mdi-information-outline</v-icon></v-chip>
                                </template>
                                <span>{{  item.promocao | formataTextoProdutoCd }} Válida de {{  item.dtinipromocao | formataData }} à {{  item.dtfimpromocao | formataData}}</span>
                              </v-tooltip>

                            </div>
                            <div class="text-subtitle-2 text-center" v-else>
                              {{ item.preco | formataDinheiro }}
                            </div>
                          </template>
                  </v-data-table>
<!--                  <v-pagination-->
<!--                      class="mx-auto"-->
<!--                      v-model="pgSelecionada3"-->
<!--                      :length="Math.ceil(produtos.total/pgLimitInt)"-->
<!--                      :total-visible="5"-->
<!--                      prev-icon="mdi-menu-left"-->
<!--                      next-icon="mdi-menu-right"-->
<!--                  ></v-pagination>-->
                </v-card-text>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-card-subtitle class="py-4 px-5 d-flex">
                  <div class="subtitle-1 font-weight-bold">Teclado Númerico</div>
                  <v-spacer/>
                  <v-badge dot :value="busca.flagestoque?1:0" overlap color="green" offset-x="7" offset-y="5">
                    <v-icon @click="sheet = !sheet">mdi-filter-menu</v-icon>
                  </v-badge>
                </v-card-subtitle>
                <v-progress-linear :active="carregando" indeterminate color="primary"></v-progress-linear>
                <v-divider class="d-block d-md-none"/>
                <v-card-text class="pa-4 pb-1">
                  <v-text-field
                      filled
                      v-model="codigoDeBarras"
                      label="Código de barras ou código interno"
                      hide-details
                      dense
                      readonly
                      autofocus
                      append-icon="mdi-close"
                      @click:append="codigoDeBarras=''"
                  ></v-text-field>
                </v-card-text>
                <v-card-text class="pa-3">
                  <div class="teclado-row">
                    <v-btn x-large height="70px" elevation="0" v-for="n in [1, 2, 3]" :key="n" @click="addNumero(n)">{{ n }}</v-btn>
                  </div>
                  <div class="teclado-row">
                    <v-btn x-large height="70px" elevation="0" v-for="n in [4, 5, 6]" :key="n" @click="addNumero(n)">{{ n }}</v-btn>
                  </div>
                  <div class="teclado-row">
                    <v-btn x-large height="70px" elevation="0" v-for="n in [7, 8, 9]" :key="n" @click="addNumero(n)">{{ n }}</v-btn>
                  </div>
                  <div class="teclado-row">
                    <v-btn
                        x-large
                        height="70px"
                        elevation="0"
                        @click="deletaUltimo"
                        class="error"
                        @mousedown="startLongPress"
                        @mouseup="cancelLongPress"
                        @mouseleave="cancelLongPress"
                        @touchstart="startLongPress"
                        @touchend="cancelLongPress"
                    >
                      <v-icon color="white">mdi-backspace</v-icon></v-btn>
                    <v-btn x-large height="70px" elevation="0" @click="addNumero(0)">0</v-btn>
                    <v-btn :loading="carregando" x-large height="70px" elevation="0" @click="buscaProduto(1,'P')" class="primary py-3"><v-icon size="30" color="white">mdi-magnify</v-icon></v-btn>
                  </div>
                </v-card-text>
              </v-tab-item>
              <v-tab-item value="tab-3">
                <v-card-subtitle class="py-4 px-5 d-flex">
                  <div class="subtitle-1 font-weight-bold">Scanner de Código de Barras</div>
                  <v-spacer/>
                  <v-badge dot :value="1" overlap color="blue" offset-x="7" offset-y="5">
                    <v-icon @click="sheet = !sheet">mdi-filter-menu</v-icon>
                  </v-badge>
                </v-card-subtitle>
                <v-progress-linear :active="carregando" indeterminate color="primary"></v-progress-linear>
                <v-divider class="d-block d-md-none"/>
                <!--                <v-card-text class="pa-4 pb-1">-->
                <!--                  <v-text-field-->
                <!--                      filled-->
                <!--                      v-model.number="codigoDeBarras"-->
                <!--                      label="Código de barras"-->
                <!--                      hide-details-->
                <!--                      dense-->
                <!--                      class="pb-1"-->
                <!--                      readonly-->
                <!--                      autofocus-->
                <!--                  >-->
                <!--                    <template v-slot:append>-->
                <!--                      <v-badge class="d-block d-md-none" dot overlap color="green" :content="1" :value="1">-->
                <!--                        <v-icon @click="sheet = !sheet">mdi-menu</v-icon> &lt;!&ndash; Ícone clicável &ndash;&gt;-->
                <!--                      </v-badge>-->
                <!--                    </template>-->
                <!--                  </v-text-field>-->
                <!--                </v-card-text>-->
                <v-card-text v-if="carregando && carregandoCamera">
                  <v-skeleton-loader type="image" />
                </v-card-text>
                <v-card-text v-else-if="cameraMode==='auto'">
<!--                  <v-col cols="12" class="px-2 py-0">-->
<!--                    <div>-->
<!--                      <v-switch hide-details class="mt-0" color="success" v-model="addAutomatico" label="Adicionar etiqueta na leitura"></v-switch>-->
<!--                    </div>-->
<!--                  </v-col>-->
                  <div class="scanner">
                    <qrcode-stream
                        :camera="cameraMode"
                        ref="qrcodeStream"
                        :track="customTrack"
                        :torch="flash"
                        :formats="['ean_13','code_128']"
                        @decode="onDecode"
                        @init="onInit"
                    />

                    <div class="corner-rectangle" v-if="!carregandoCamera">
                      <!-- Vértices superiores -->
                      <div class="corner top-left"></div>
                      <div class="corner top-right"></div>
                      <!-- Vértices inferiores -->
                      <div class="corner bottom-left"></div>
                      <div class="corner bottom-right"></div>

                      <!-- Linha vermelha horizontal (efeito de laser) -->
                      <div class="laser-line"></div>
                    </div>

                    <v-btn v-if="!flash" icon dark @click="flash = !flash" class="mt-3 primary add-button">
                      <v-icon>mdi-flashlight</v-icon>
                    </v-btn>
                    <v-btn v-else icon dark @click="flash = !flash" class="mt-3 primary add-button">
                      <v-icon>mdi-flashlight-off</v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
        <v-bottom-sheet v-model="sheet" persistent>
          <v-sheet class="text-center" height="100%">
            <v-row no-gutters>
              <v-col cols="12" class="pa-5" v-if="tab==='tab-3'">
                <div class="d-flex">
                  <v-tooltip left color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-switch disabled hide-details class="mt-0" color="success" v-model="busca.flagestoque2" label="Inclui na busca produtos sem estoque" :value="true"></v-switch>
                      <v-icon
                          v-bind="attrs"
                          v-on="on"
                          color="success"
                          class="ml-1 mb-n1">mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Ativo por padrão no modo scanner</span>
                  </v-tooltip>
                </div>
<!--                <v-switch hide-details class="mt-3" color="success" v-model="addAutomatico" label="Adicionar produto na leitura" :value="true"></v-switch>-->
              </v-col>
              <v-col cols="12" class="pa-5" v-else>
                <div>
                  <v-switch hide-details class="mt-0" color="success" v-model="busca.flagestoque" label="Inclui na busca produtos sem estoque"></v-switch>
                </div>
              </v-col>
              <v-col cols="12" class="pa-4">
                <v-btn text outlined block class="mt-n3" color="primary" @click="sheet = !sheet">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-sheet>
        </v-bottom-sheet>
        <v-btn
            v-if="listaProdutosSelecionados.lista.length > 0"
            :disabled="carregando"
            :loading="carregando"
            @click="dialogSelecionados = true"
            fab
            fixed
            bottom
            right
            color="primary"
            large
            class="mb-2 d-md-none"
        >
          <v-badge color="green" :content="listaProdutosSelecionados.lista.length" :value="listaProdutosSelecionados.lista.length">
            <v-icon large>mdi-content-save</v-icon>
          </v-badge>
        </v-btn>
        <v-snackbar
            class="mb-2"
            :color="snackbar2.color"
            v-model="snackbar2.status"
            :timeout="snackbar2.timeout"
            bottom
            height="80px"
            text
            width="100%"
            outlined
        >
          <span class="font-weight-bold text-uppercase">{{snackbar2.text}}</span>
        </v-snackbar>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmaContinuacao" persistent width="400px">
      <v-card>
        <v-card-title class="px-5">
          <div>Lista em Progresso</div>
          <v-spacer />
          <v-btn @click="dialogConfirmaContinuacao = false" icon class="mr-n1"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-progress-linear indeterminate color="primary" :active="carregando" />
        <v-divider/>
        <v-card-text class="pa-5">
          <div class="text-body-2 black--text text-justify">
            Existe uma lista de {{qtdItensProgresso>1?qtdItensProgresso+' itens':qtdItensProgresso+' item'}} em processo de <strong>{{tipoProgresso}}</strong>, deseja começar uma nova ou continuar de onde parou?
          </div>
        </v-card-text>
        <v-card-text class="pa-4 pt-0">
          <v-alert color="error" outlined class="text-caption mb-0 pa-3">
            <v-row>
              <v-col cols="2" class="text-center align-content-center px-2">
                <v-icon color="error">mdi-alert</v-icon>
              </v-col>
              <v-col class="pl-0 pr-2">
                Atenção, caso clique em <strong>"COMEÇAR NOVA LISTA"</strong> o progresso anterior sera perdido!
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3 pb-2">
          <v-row no-gutters>
            <v-col cols="12" class="pb-1">
              <v-btn :disabled="carregando" @click="resetaCache()" block color="error ma-0" text>COMEÇAR NOVA LISTA</v-btn>
            </v-col>
            <v-col cols="12" class="pb-1">
              <v-btn :disabled="carregando" @click="atribuiCache()" block color="primary" class="ma-0">Continuar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="500px" v-model="dialogDetalhes" no-click-animation persistent content-class="dialog-detalhes" scrollable :overlay-opacity="0.9">
      <v-row no-gutters class="mb-11 mt-8">
        <v-col class="pa-0 mb-18">
          <v-col cols="12" v-for="(produto) in produtos.lista" :key="produto.compositeKey" class="px-0 pt-0 pb-3">
            <v-card outlined elevation="0">
              <v-skeleton-loader v-if="carregando" type="card-heading" />
              <v-card-title class="primary white--text font-weight-medium pa-2 px-4 text-caption" v-else>
                {{ produto.idsubproduto }} - {{ produto.produto }}
              </v-card-title>
              <v-divider />
              <v-card-text class="pa-0 px-4" v-if="carregando">
                <v-skeleton-loader type="list-item-three-line" />
              </v-card-text>
              <v-card-text class="py-2 px-4" v-else>
                Código de barras <span class="float-end"> {{ produto.codigobarra }} </span> <br />
                Embalagem Saída <span class="float-end"> {{ produto.valsaida }} {{ produto.embalagemsaida }} </span> <br />
                Embalagem Entrada <span class="float-end"> {{ produto.valentrada }} {{ produto.embalagementrada }} </span> <br />
                Preço Varejo <span class="float-end"> {{ produto.preco | formataDinheiro }} </span> <br />
                Preço Promoção <span class="float-end"> {{ produto.precopromocao | formataDinheiro }} </span>
              </v-card-text>
              <v-divider />
              <v-card-actions class="px-3">
                <v-row no-gutters>
                  <v-col v-if="!listaProdutosSelecionados.lista.find(u => u.compositeKey === produto.compositeKey)" class="py-1">
                    <v-btn
                        elevation="0"
                        color="primary"
                        block
                        @click="addItem(produto)"
                        :loading="carregando"
                        :disabled="carregando"
                    >
                      <v-icon class="mr-1">mdi-plus</v-icon> Adicionar
                    </v-btn>
                  </v-col>
                  <v-col v-else>
                    <div class="d-flex align-center">
                      <v-btn :disabled="carregando" @click="decrementaQuantidade('detalhes',produto)" small rounded width="36" height="36" elevation="0" class="red px-0" dark>
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <v-text-field
                          class="mx-3"
                          label="Qtde Etiquetas"
                          hide-details
                          dense
                          filled
                          readonly
                          :disabled="carregando"
                          v-model.number="produto.quantidade"
                          type="number"
                          @input="verificaQuantidade(produto)"
                      ></v-text-field>
                      <v-btn :disabled="carregando" @click="incrementaQuantidade('detalhes', produto)" small rounded width="36" height="36" elevation="0" class="primary px-0" dark>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-col>
        <v-col cols="12">
          <v-app-bar v-if="showBar" height="auto" fixed elevation="3" class="white px-3">
            <v-btn :disabled="pgSelecionada2===1 || carregando" @click="buscaProduto(pgSelecionada2-1,'P')" height="30" width="30" icon color="primary"><v-icon>mdi-chevron-left</v-icon></v-btn>
            <v-spacer />
            <v-progress-circular size="16" color="primary" width="3" class="mr-2" indeterminate v-if="carregando"></v-progress-circular>
            <span v-else>{{(pgSelecionada2*pgLimitInt2)-pgLimitInt2+1}}-{{(pgSelecionada2*pgLimitInt2)>produtos.total?produtos.total:(pgSelecionada2*pgLimitInt2)}} </span> /{{produtos.total}}
            <v-spacer />
            <v-btn :disabled="pgSelecionada2===Math.ceil(produtos.total/pgLimitInt2) || carregando" @click="buscaProduto(pgSelecionada2+1,'P')" height="30" width="30" icon color="primary"><v-icon>mdi-chevron-right</v-icon></v-btn>
<!--            <v-pagination-->
<!--                class="mx-auto"-->
<!--                v-model="pgSelecionada2"-->
<!--                :length="Math.ceil(produtos.total/pgLimitInt)"-->
<!--                :total-visible="5"-->
<!--                prev-icon="mdi-menu-left"-->
<!--                next-icon="mdi-menu-right"-->
<!--            ></v-pagination>-->
          </v-app-bar>
          <v-app-bar v-if="showBar" height="auto" fixed elevation="3" class="white py-2" bottom>
<!--            <v-row no-gutters>-->
<!--              <v-col v-if="(produtos.total/pgLimitInt)>1">-->
<!--                <v-pagination-->
<!--                    class="mx-auto"-->
<!--                    v-model="pgSelecionada2"-->
<!--                    :length="produtos.total/pgLimitInt"-->
<!--                    :total-visible="5"-->
<!--                    prev-icon="mdi-menu-left"-->
<!--                    next-icon="mdi-menu-right"-->
<!--                ></v-pagination>-->
<!--              </v-col>-->
            <v-row>
              <v-col>
                <v-btn :disabled="carregando" class="primary" block @click="cancelaInclucao(), cameraMode='auto'" elevation="0"><v-icon class="mr-1">mdi-close</v-icon>Fechar</v-btn>
              </v-col>
            </v-row>
<!--            </v-row>-->
          </v-app-bar>
        </v-col>
        <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.status"
            :timeout="snackbar.timeout"
            height="50px"
            bottom
            text
            width="100%"
            outlined
            class="mb-16"
        >
          <span class="font-weight-bold text-uppercase">{{snackbar.text}}</span>
        </v-snackbar>
      </v-row>
    </v-dialog>
    <v-dialog max-width="500px" v-model="dialogSelecionados" no-click-animation persistent content-class="dialog-detalhes" scrollable :overlay-opacity="0.9" eager>
      <v-row no-gutters>
        <v-col cols="12" v-for="(produto) in listaProdutosSelecionados.lista" :key="produto.compositeKey">
          <v-card
              class="mb-4" outlined elevation="0"
          >
            <v-skeleton-loader v-if="carregando" type="card-heading" />
            <v-card-title class="primary white--text font-weight-medium pa-2 px-4 text-caption" v-else>
              {{ produto.idsubproduto }} - {{ produto.produto }}
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0 px-4" v-if="carregando">
              <v-skeleton-loader type="list-item" />
              <v-skeleton-loader type="list-item-three-line" />
            </v-card-text>
            <v-card-text class="py-2 px-4" v-else>
              Código de barras <span class="float-end"> {{ produto.codigobarra }} </span> <br />
              Embalagem Saída <span class="float-end"> {{ produto.valsaida }} {{ produto.embalagemsaida }} </span> <br />
              Embalagem Entrada <span class="float-end"> {{ produto.valentrada }} {{ produto.embalagementrada }} </span> <br />
              Preço Varejo <span class="float-end"> {{ produto.preco | formataDinheiro }} </span> <br />
              Preço Promoção <span class="float-end"> {{ produto.precopromocao | formataDinheiro }} </span>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-3">
              <v-row no-gutters>
                <v-col v-if="!listaProdutosSelecionados.lista.find(u => u.compositeKey === produto.compositeKey)" class="py-1">
                  <v-btn
                      elevation="0"
                      color="primary"
                      block
                      @click="addItem(produto)"
                      :loading="carregando"
                      :disabled="carregando"
                  >
                    <v-icon small class="mr-1">mdi-plus</v-icon> Adicionar
                  </v-btn>
                </v-col>
                <v-col v-else>
                  <div class="d-flex align-center">
                    <v-btn :disabled="carregando" @click="decrementaQuantidade('selecionados',produto)" small rounded width="36" height="36" elevation="0" class="red px-0" dark>
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-text-field
                        class="mx-3"
                        label="Qtde Etiquetas"
                        hide-details
                        dense
                        filled
                        :disabled="carregando"
                        v-model.number="produto.quantidade"
                        type="number"
                        @input="verificaQuantidade(produto)"
                    ></v-text-field>
                    <v-btn :disabled="carregando" @click="incrementaQuantidade('selecionados', produto)" small rounded width="36" height="36" elevation="0" class="primary px-0" dark>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-app-bar v-if="showBar && !concluir" height="auto" fixed elevation="3" class="white py-2" bottom>
            <v-row no-gutters >
              <v-col cols="6" class="pr-2">
                <v-btn class="primary" block @click="cancelaInclucao(),cameraMode='auto'" elevation="0"><v-icon class="mr-1">mdi-arrow-left</v-icon>Voltar</v-btn>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-btn class="success" block @click="concluir = true" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0"><v-icon class="mr-1">mdi-check</v-icon>Concluir</v-btn>
              </v-col>
            </v-row>
<!--            <v-row no-gutters v-else>-->
<!--              <v-col cols="12" class="pr-2">-->
<!--                <v-text-field ref="inputField" autofocus @focus="handleFocus" counter maxlength="20" filled label="Nome da Lista" dense class="mb-n2" v-model="nomeLista" append-icon="mdi-close" @click:append="nomeLista=''"></v-text-field>-->
<!--                <v-divider class="my-1" />-->
<!--              </v-col>-->
<!--              <v-col cols="6" class="pr-1">-->
<!--                <v-btn class="primary mt-2" block @click="concluir = false" elevation="0"><v-icon class="mr-1">mdi-arrow-left</v-icon>Voltar</v-btn>-->
<!--              </v-col>-->
<!--              <v-col cols="6" class="pl-1">-->
<!--                <v-btn class="error mt-2" block @click="dialogConfirmaDesfazimento=true;" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0"><v-icon class="mr-1">mdi-undo-variant</v-icon>Desfazer</v-btn>-->
<!--              </v-col>-->
<!--              <v-col cols="12">-->
<!--                <v-btn v-if="!idlista" class="success mt-2" block @click="listaCadastrar()" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0 || nomeLista.length===0"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>-->
<!--                <v-btn v-else class="success mt-2" block @click="listaAtualizar()" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0 || nomeLista.length===0"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar Alterações</v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
          </v-app-bar>
          <v-bottom-sheet v-else-if="showBar" v-model="concluir" persistent>
            <v-sheet class="text-center" height="100%">
              <v-row no-gutters class="pa-4">
                <v-col cols="12">
                  <v-text-field ref="inputField" autofocus @focus="handleFocus" counter maxlength="20" filled label="Nome da Lista" dense class="mb-n2" v-model="nomeLista" append-icon="mdi-close" @click:append="nomeLista=''"></v-text-field>
                  <v-divider class="my-1" />
                </v-col>
                <v-col cols="6" class="pr-1">
                  <v-btn class="primary mt-2" block @click="concluir = false" elevation="0"><v-icon class="mr-1">mdi-arrow-left</v-icon>Voltar</v-btn>
                </v-col>
                <v-col cols="6" class="pl-1">
                  <v-btn class="error mt-2" block @click="dialogConfirmaDesfazimento=true;" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0"><v-icon class="mr-1">mdi-undo-variant</v-icon>Desfazer</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn v-if="!idlista" class="success mt-2" block @click="listaCadastrar()" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0 || nomeLista.length===0"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>
                  <v-btn v-else class="success mt-2" block @click="listaAtualizar()" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0 || nomeLista.length===0"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar Alterações</v-btn>
                </v-col>
              </v-row>
            </v-sheet>
          </v-bottom-sheet>
        </v-col>
        <v-snackbar
            class="mb-16"
            :color="snackbar4.color"
            v-model="snackbar4.status"
            :timeout="snackbar4.timeout"
            bottom
            height="50px"
            text
            width="100%"
            outlined
        >
          <span class="font-weight-bold text-uppercase">{{snackbar4.text}}</span>
        </v-snackbar>
      </v-row>
    </v-dialog>
    <v-dialog v-model="dialogSelecionadosPc" fullscreen persistent>
      <v-card>
        <v-card-title class="primary white--text pl-5 py-4 pr-3">
          Lista de Produtos Adicionados
          <v-spacer />
          <v-btn @click="dialogSelecionadosPc = false" icon class="mr-1"><v-icon color="white">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card elevation="0" class="mt-0">
          <v-card-subtitle>
            <v-row no-gutters class="pa-1">
              <v-col cols="12">
                <v-text-field ref="inputField" autofocus @focus="handleFocus" counter maxlength="20" filled label="Nome da Lista" dense class="mb-n2" v-model="nomeLista" append-icon="mdi-close" @click:append="nomeLista=''"></v-text-field>
                <v-divider class="my-1" />
              </v-col>
              <v-col cols="6" class="pr-1">
                <v-btn class="error mt-2" block @click="dialogConfirmaDesfazimento=true;" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0"><v-icon class="mr-1">mdi-undo-variant</v-icon>Cancelar e começar uma nova lista</v-btn>
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-btn v-if="!idlista" class="success mt-2" block @click="listaCadastrar()" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0 || nomeLista.length===0"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar</v-btn>
                <v-btn v-else class="success mt-2" block @click="listaAtualizar()" elevation="0" :disabled="listaProdutosSelecionados.lista.length===0 || nomeLista.length===0"><v-icon class="mr-1">mdi-content-save</v-icon>Salvar Alterações</v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider />
          <v-card-text class="pa-0 d-md-block d-none">
            <v-data-table
                :headers="[
                          { text: 'Cód. Barras', value: 'codigobarra', align:'left', sortable:false, class:'text-truncate'},
                          { text: 'Cód. Produto', value: 'idsubproduto', align:'left', sortable:false, class:'text-truncate'},
                          { text: 'Produto', value: 'produto', align:'left', sortable:false, class:'text-truncate'},
                          { text: 'Emb. Saída', value: 'embalagemsaida', align:'center', sortable:false, class:'text-truncate'},
                          { text: 'Emb. Entrada', value: 'embalagementrada', align:'center', sortable:false, class:'text-truncate'},
                          { text: 'Preço', value: 'preco', align:'center', sortable:false, class:'text-truncate'},
                          { text: 'Qtde Etiquetas', value: 'quantidade', align:'center', sortable:false, class:'text-truncate'},
                      ]"
                :items="listaProdutosSelecionados.lista"
                height="calc(100vh - 250px)"
                fixed-header
                v-model="listaProdutosSelecionados.lista"
                item-key="compositeKey"
                hide-default-footer
                :items-per-page="-1"
                class="pb-2"
            >
              <template v-slot:item.quantidade="{ item }">
                <td width="180px">
                  <v-row no-gutters>
                    <v-col v-if="!listaProdutosSelecionados.lista.find(u => u.compositeKey === item.compositeKey)" class="py-1">
                      <v-btn
                          elevation="0"
                          color="primary"
                          block
                          @click="addItem(item)"
                          :loading="carregando"
                          :disabled="carregando"
                          small
                          rounded
                      >
                        <v-icon small class="mr-1">mdi-plus</v-icon> Adicionar
                      </v-btn>
                    </v-col>
                    <v-col v-else>
                      <div class="d-flex align-center">
                        <v-btn icon :disabled="carregando" @click="decrementaQuantidade('selecionados',item)" x-small width="28" height="28" elevation="0" class="red px-0" dark>
                          <v-icon size="20">mdi-minus</v-icon>
                        </v-btn>
                        <v-text-field
                            hide-details
                            dense
                            reverse
                            readonly
                            :disabled="carregando"
                            v-model.number="item.quantidade"
                            type="number"
                            @input="verificaQuantidade(item)"
                            class="ml-2 mr-3"
                            style="text-align: center !important;"
                        >
                        </v-text-field>
                        <v-btn icon :disabled="carregando" @click="incrementaQuantidade('selecionados',item)" x-small width="28" height="28" elevation="0" class="primary px-0 ml-n1" dark>
                          <v-icon size="20">mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:item.codigobarra="{ item }">
                {{  item.codigobarra }}
              </template>
              <template v-slot:item.idsubproduto="{ item }">
                {{  item.idsubproduto }}
              </template>
              <template v-slot:item.produto="{ item }">
                {{  item.produto }}
              </template>
              <template v-slot:item.embalagemsaida="{ item }">
                <td class="text-center">{{ item.valsaida }} {{  item.embalagemsaida }}</td>
              </template>
              <template v-slot:item.embalagementrada="{ item }">
                <td class="text-center">{{ item.valentrada }} {{  item.embalagementrada }}</td>
              </template>
              <template v-slot:item.preco="{ item }">
                <div style="display: flex; flex-direction: column; align-items: center" v-if="item.promocao">
                              <span class="text-subtitle-2 text-truncate text-decoration-line-through">
                                {{ item.preco | formataDinheiro }}
                              </span>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" small class="font-weight-bold success mb-2">{{  item.precopromocao | formataDinheiro }}<v-icon x-small color="white" class="ml-1 mt-n1">mdi-information-outline</v-icon></v-chip>
                    </template>
                    <span>{{  item.promocao | formataTextoProdutoCd }} Válida de {{  item.dtinipromocao | formataData }} à {{  item.dtfimpromocao | formataData}}</span>
                  </v-tooltip>

                </div>
                <div class="text-subtitle-2 text-center" v-else>
                  {{ item.preco | formataDinheiro }}
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmaDelecao" persistent width="400px">
      <v-card>
        <v-card-title class="px-5 justify-center">
          Deleção de Lista
        </v-card-title>
        <v-progress-linear indeterminate color="primary" :active="carregando" />
        <v-divider/>
        <v-card-text class="pa-5 justify-center">
          <div class="text-body-2 black--text text-center">
            Confirma a deleção da lista contendo {{listaTemp.qtditens>1?listaTemp.qtditens+' itens':listaTemp.qtditens+' item'}}? <br/> <strong>Lista: "{{listaTemp.idlista}} - {{listaTemp.lista}}"</strong>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-row no-gutters>
            <v-col class="pr-1">
              <v-btn :disabled="carregando" @click="cancelaInclucao" block color="primary" class="ma-0" text>Cancelar</v-btn>
            </v-col>
            <v-col class="pl-1">
              <v-btn :disabled="carregando" @click="listaDeletar" block class="error ma-0">Confirmar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmaDesfazimento" persistent width="400px">
      <v-card>
        <v-card-title class="px-5 justify-center">
          Cancelamento de Progresso
        </v-card-title>
        <v-progress-linear indeterminate color="primary" :active="carregando" />
        <v-divider/>
        <v-card-text class="pa-5 justify-center">
          <div class="text-body-2 black--text text-center">
            Confirma o cancelamento do progresso? <br/> <strong>Todo o trabalho será perdido</strong>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-3">
          <v-row no-gutters>
            <v-col class="pr-1">
              <v-btn :disabled="carregando" @click="dialogConfirmaDesfazimento=false" block color="primary" class="ma-0" text>Cancelar</v-btn>
            </v-col>
            <v-col class="pl-1">
              <v-btn :disabled="carregando" @click="cancelaProgresso" block class="error ma-0">Confirmar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmaImpressao" width="450px" persistent>
      <v-card>
        <v-card-title>
          Geração de Arquivo
        </v-card-title>
        <v-divider/>
        <v-card-text class="py-5 text-justify">
          Lista: <strong>"{{listaTemp.idlista}} - {{listaTemp.lista}}"</strong> <br/>
          Quantidade de Itens: <strong>{{listaTemp.qtditens}}</strong> <br/>
          Confirma a geração do arquivo de etiquetas?
        </v-card-text>
<!--        <v-divider class="d-block d-sm-none"/>-->
        <v-card-text class="pa-5 pt-0 d-block d-sm-none">
          <v-alert color="error" outlined class="text-caption mb-0 pa-0">
            <v-card-subtitle class="text-center py-1">
              <v-icon color="error" size="20" class="mr-1 mt-n1">mdi-information-outline</v-icon> AVISO IMPORTANTE
            </v-card-subtitle>
            <v-divider style="background-color: red" />
            <v-card-text class="text-justify py-2 px-3 text-caption">
              É recomendado que a geração do arquivo de etiquetas seja efetuada por um computador. Essa mesma relação de etiquetas, também pode ser visualizada pelo computador.
            </v-card-text>
          </v-alert>
        </v-card-text>
        <v-divider/>
        <v-card-actions class="pt-3 pb-2">
          <v-row no-gutters>
          <v-col cols="5" class="pr-1 pb-1"><v-btn elevation="0" color="error" @click="dialogConfirmaImpressao= false" width="100%" text>Cancelar</v-btn></v-col>
          <v-col cols="7" class="pl-1 pb-1 d-none d-sm-block"><v-btn class="primary" @click="geraEtiquetas()" width="100%">Gerar Etiquetas <v-icon class="ml-2">mdi-download</v-icon></v-btn></v-col>
          <v-col cols="7" class="pl-1 pb-1 d-sm-none"><v-btn class="primary" @click="geraEtiquetas()" width="100%">Gerar <v-icon class="ml-2">mdi-download</v-icon></v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="carregandoPdf" persistent overlay-opacity="0.7" width="500px">
      <v-card color="primary" dark>
        <v-card-text class="pa-5">
          <span>{{ carregandoPdfMensagem }}...</span>
          <v-progress-linear stream color="white"  buffer-value="0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="pa-3 pa-md-4">
        Gestão de Gôndolas<br/>
        <v-spacer />
        <v-btn :loading="carregando" class="primary d-block d-md-none" small elevation="0" @click="verificaContinuacao('criar')"> Criar Lista </v-btn>
        <v-btn :loading="carregando" class="primary d-md-block d-none" elevation="0" @click="verificaContinuacao('criar')"> Criar Lista </v-btn>
      </v-card-title>
      <v-progress-linear indeterminate color="primary" :active="carregando" />
      <v-divider />
      <v-card-text class="pa-0">
        <v-data-table
            mobile-breakpoint="0"
            hide-default-footer :headers="[
            { text: 'ID', value: 'idlista', align: 'start', sortable: false  },
            { text: 'Nome', value: 'lista', align: 'start', sortable: false  },
            { text: 'Qtde Itens', value: 'qtditens', align: 'center', sortable: false  },
            { text: 'Usu. Cadastro', class: 'd-md-table-cell d-none', value: 'usucadastro', align: 'center', sortable: false  },
            { text: 'Data de Cadastro', class: 'd-md-table-cell d-none', value: 'dtcadastro', align: 'center', sortable: false  },
            { text: 'Usu. Alteração', class: 'd-md-table-cell d-none', value: 'usuultalteracao', align: 'center', sortable: false  },
            { text: 'Data de Alteração', class: 'd-md-table-cell d-none', value: 'dtultalteracao', align: 'center', sortable: false  },
            { text: 'Ações', value: 'acoes', align: 'center', sortable: false  },
          ]"
          :items="lista.lista"
          :items-per-page="pgLimitInt"
        >
          <template v-slot:item.lista="{ item }">
            <td class="text-truncate">  {{item.lista}}</td>
          </template>
          <template v-slot:item.usucadastro="{ item }">
            <td class="d-md-table-cell d-none text-center"> {{item.usucadastro}}</td>
          </template>
          <template v-slot:item.dtcadastro="{ item }">
            <td class="d-md-table-cell d-none text-center"> {{item.dtcadastro | formataData}}</td>
          </template>
          <template v-slot:item.usuultalteracao="{ item }">
            <td class="d-md-table-cell d-none text-center"> {{item.usuultalteracao }}</td>
          </template>
          <template v-slot:item.dtultalteracao="{ item }">
            <td class="d-md-table-cell d-none text-center"> {{item.dtultalteracao | formataData}}</td>
          </template>
          <template v-slot:item.acoes="{ item }">
            <td class="text-center" width="5%" nowrap>
              <v-menu
                  bottom
                  right
                  offset-y
              >
                <!-- Botão visível que abre o menu -->
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      :loading="carregando"
                      v-bind="attrs"
                      v-on="on"
                      small
                      dark
                      outlined
                      rounded
                      color="primary"
                      class="d-none d-md-block"
                  >
                    <span>opções</span>
                    <v-icon small class="ml-1">mdi-dots-vertical</v-icon>
                  </v-btn>
                  <v-btn
                      :loading="carregando"
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      dark
                      outlined
                      color="primary"
                      class="d-md-none d-block"
                  >
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <!-- Conteúdo do menu com os outros botões -->
                <v-list class="primary--text" dense>
                  <v-list-item @click="abrirDialogConfirmaImpressao(item)">
                    <v-list-item-icon class="mr-3">
                      <v-icon color="primary">mdi-printer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Imprimir</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="listaProdutosListar(0,item)">
                    <v-list-item-icon class="mr-3">
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="dialogConfirmaDelecao = true; listaTemp = item">
                    <v-list-item-icon class="mr-3">
                      <v-icon color="error">mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="error--text">Deletar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
        <v-divider />
        <Paginacao
            class="py-2"
            @atualizar="listaListar"
            :total="lista.total"
            :pgLimitInt="pgLimitInt"
            :carregando="carregando"
            :pg="pgSelecionada"
        />
      </v-card-text>
    </v-card>
    <v-snackbar
        class="mb-16"
        :color="snackbar3.color"
        v-model="snackbar3.status"
        :timeout="snackbar3.timeout"
        bottom
        height="50px"
        text
        width="100%"
        outlined
    >
      <span class="font-weight-bold text-uppercase">{{snackbar3.text}}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import axios from "axios";
import Paginacao from "@/Components/Widgets/Paginacao.vue";
import {mapState} from "vuex";
import html2pdf from "html2pdf.js";

export default {
  components: {
    Paginacao,
    QrcodeStream,
  },
  data() {
    return {
      cameraMode:'auto',

      idlista: null,
      dialogRetorno: { status: false },
      nomeLista: '',
      concluir: false,

      lista: { lista: [], total: 0},
      listaProdutos: { lista: [], total: 0},
      busca: {
        flagestoque: false,
        flagestoque2: true,
      },

      addAutomatico: false,

      pgLimitInt: 20,
      pgLimitInt2: 50,

      pgSelecionada: 0,
      pgSelecionada2: 1,
      pgSelecionada3: 1,

      sheet: false,

      showBar: false,
      dynamicPlaceholder: '', // Inicializa a variável
      dialog:false,
      dialogDetalhes: false,
      dialogSelecionados: false,
      dialogSelecionadosPc: false,
      dialogConfirmaDelecao: false,
      dialogConfirmaDesfazimento: false,
      dialogConfirmaContinuacao: false,
      dialogConfirmaImpressao: false,

      carregandoPdfMensagem: '',

      isLoading: false,

      qtdItensProgresso:0,
      tipoProgresso: '',

      listaTemp: {},

      tab:'tab-1',
      codigoDeBarras: '',
      flash: false,
      detectedCodes: [], // Armazena os códigos detectados
      produtos: {lista: []},

      snackbar: { status: false, timeout: 1500, color:'success' },
      snackbar2: { status: false, timeout: 5000, color:'success' },
      snackbar3: { status: false, timeout: 2000, color:'success' },
      snackbar4: { status: false, timeout: 2000, color:'success' },

      listaProdutosSelecionados: { lista: [] },

      listaProdutosImpressao:{ lista: [] },

      carregando:false,
      carregandoPdf:false,
      carregandoCamera:false,
    };
  },
  computed: {
    ...mapState(["backendUrl", "pgLimit", "usuario"]),
  },
  watch: {
    dialogDetalhes(val) {
      if (val) {
        setTimeout(() => {
          this.showBar = true;
        }, 600);
      } else {
        this.showBar = false;
      }
    },
    dialogSelecionados(val) {
      if (val) {
        setTimeout(() => {
          this.showBar = true;
        }, 600);
      } else {
        this.showBar = false;
      }
    },
    'tab': function() {
      if (this.tab === 'tab-3'){
        this.cameraMode='auto';
        this.carregando = true;
        this.carregandoCamera = true;
        setTimeout(() => {
          this.carregando = false;
        }, 500);
        setTimeout(() => {
          this.carregandoCamera = false;
        }, 2500);
      } else {
        this.cameraMode='off'
      }
      this.codigoDeBarras='';
    }
  },
  methods: {
    abrirDialogConfirmaImpressao(item){
      this.dialogConfirmaImpressao = true;
      this.listaTemp = item;
    },
    onInit(promise) {
      promise.then(() => {
        setTimeout(() => {
          this.isLoading = false;  // Quando a câmera estiver pronta, escondemos o carregamento
        }, 1000);
      });
    },
    verificaContinuacao(tipo){
      if (tipo==='criar'){
        this.listaProdutosSelecionados = { lista: [] };
      }
      const json = localStorage.getItem("etiquetas");
      const etiquetas = JSON.parse(json);
      const cache = etiquetas ? JSON.stringify(etiquetas.lista.map((v) => {return{compositeKey: v.compositeKey, quantidade: v.quantidade }})) : null;
      const banco = JSON.stringify(this.listaProdutosSelecionados.lista.map((v) => {return{compositeKey: v.compositeKey, quantidade: v.quantidade }}));

      if (etiquetas && cache !== banco) {
        this.tipoProgresso = etiquetas.lista[0].idlista == null ? 'criação' : 'alteração';
        this.qtdItensProgresso=etiquetas.lista.length;
        this.dialogConfirmaContinuacao=true;
      } else {
        this.dialog = true;
      }
    },

    resetaCache(){
      this.idlista=null;
      this.nomeLista='';
      this.codigoDeBarras='';
      this.dialogConfirmaContinuacao=false;
      this.dialog = true;
      localStorage.removeItem("etiquetas");
    },

    atribuiCache(){
      this.dialogConfirmaContinuacao=false;
      this.carregando=true;
      const json = localStorage.getItem("etiquetas");
      const etiquetas = JSON.parse(json);
      this.listaProdutosSelecionados = etiquetas;
      this.idlista = etiquetas.lista[0].idlista;
      this.dialog = true;
      setTimeout(() => {
        this.carregando=false;
      }, 1000);
    },

    salvaCache(){
      localStorage.setItem("etiquetas", JSON.stringify(this.listaProdutosSelecionados));
    },

    handleFocus() {
      // Aguarda a renderização do teclado no dispositivo móvel
      setTimeout(() => {
        const inputElement = this.$refs.inputField.$el;

        // Desliza a página até o campo de input
        inputElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
      }, 300); // Ajuste o tempo conforme necessário
    },

    listaListar(pg){
      this.pgSelecionada = pg;
      this.carregando = true;
      try {
        return axios
          .post(`${this.backendUrl}etiqueta/lista/listar`, {
            limit: this.pgLimitInt,
            offset: this.pgLimitInt * (pg),
          })
          .then((res) => {
            this.lista.lista = res.data.lista == null ? [] : res.data.lista;
            this.lista.total = res.data.total;
            this.carregando = false;
          })
          .catch(() =>{
            this.carregando = false;
            window.location.reload();
          });
      } catch (error) {
        console.error("Erro ao consultar a API:", error);
      }
    },

    listaCadastrar(){
      this.carregando = true;
      this.dialogRetorno= {status: false};
      try {
        return axios
          .post(`${this.backendUrl}etiqueta/lista/cadastrar`, {
            lista: this.nomeLista,
            produtos: this.listaProdutosSelecionados.lista
          })
          .then((res) => {
            const id = res.data
            if (id>0){
              this.cancelaProgresso();
              this.resetaCache();
              this.dialog=false;
              this.dialogRetorno.status = true;
              this.dialogRetorno.title = "Sucesso";
              this.dialogRetorno.icon = "mdi-check-circle-outline";
              this.dialogRetorno.iconColor = "success";
              this.dialogRetorno.msg = `Lista ${id} cadastrada com Sucesso!`;
              this.listaListar(0);
            } else {
              this.dialogRetorno.status = true;
              this.dialogRetorno.title = "Inconsistência";
              this.dialogRetorno.msg = `Erro ao salvar lista, contate o Administrador do sistema!`;
              this.dialogRetorno.icon = "mdi-alert-circle-outline";
              this.dialogRetorno.iconColor = "error";
            }
            this.carregando = false;
          })
          .catch(() =>{
            this.dialogRetorno.status = true;
            this.dialogRetorno.title = "Inconsistência";
            this.dialogRetorno.msg = `Falha ao salvar lista, contate o Administrador do sistema!`;
            this.dialogRetorno.icon = "mdi-alert-circle-outline";
            this.dialogRetorno.iconColor = "error";
            this.carregando = false;
            window.location.reload();
          });
      } catch (error) {
        console.error("Erro ao consultar a API:", error);
      }
    },

    listaAtualizar(){
      this.carregando = true;
      this.dialogRetorno= {status: false};
      try {
        return axios
          .post(`${this.backendUrl}etiqueta/lista/atualizar`, {
            idlista: this.idlista,
            lista: this.nomeLista,
            produtos: this.listaProdutosSelecionados.lista
          })
          .then((res) => {
            const id = res.data
            if (id>0){
              this.cancelaProgresso();
              this.resetaCache();
              this.dialog=false;
              this.dialogRetorno.status = true;
              this.dialogRetorno.title = "Sucesso";
              this.dialogRetorno.icon = "mdi-check-circle-outline";
              this.dialogRetorno.iconColor = "success";
              this.dialogRetorno.msg = `Lista ${id} atualizada com Sucesso!`;
              this.listaListar(0);
            } else {
              this.dialogRetorno.status = true;
              this.dialogRetorno.title = "Inconsistência";
              this.dialogRetorno.msg = `Erro ao salvar lista, contate o Administrador do sistema!`;
              this.dialogRetorno.icon = "mdi-alert-circle-outline";
              this.dialogRetorno.iconColor = "error";
            }
            this.carregando = false;
          })
          .catch(() =>{
            this.dialogRetorno.status = true;
            this.dialogRetorno.title = "Inconsistência";
            this.dialogRetorno.msg = `Falha ao salvar lista, contate o Administrador do sistema!`;
            this.dialogRetorno.icon = "mdi-alert-circle-outline";
            this.dialogRetorno.iconColor = "error";
            this.carregando = false;
            window.location.reload();
          });
      } catch (error) {
        console.error("Erro ao consultar a API:", error);
      }
    },

    listaDeletar(){
      this.carregando = true;
      try {
        return axios
          .post(`${this.backendUrl}etiqueta/lista/deletar`, {
            idlista: this.listaTemp.idlista,
          })
          .then(() => {
            this.listaTemp={};
            this.dialogConfirmaDelecao=false;
            this.snackbar3.text = 'Lista excluída com sucesso!'
            this.snackbar3.color = 'success';
            this.snackbar3.status = true;
            this.listaListar(0);
            this.carregando = false;
          })
          .catch(() =>{
            this.carregando = false;
            window.location.reload();
          });
      } catch (error) {
        console.error("Erro ao consultar a API:", error);
      }
    },

    listaProdutosListar(pg, item){
      this.carregando = true;
      this.pgSelecionada = pg;
      setTimeout(() => {
        return axios
            .post(`${this.backendUrl}etiqueta/lista/produtos/listar`, {
              idlista: item.idlista,
              limit: 1000,
              offset: 0,
            })
            .then((res) => {
              this.listaProdutosSelecionados.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
                const compositeKey = `${v.idsubproduto}-${(v.preco - v.precopromocao).toFixed(2)}`;
                return {
                  ...v,
                  compositeKey: compositeKey,
                };
              });
              this.listaProdutosSelecionados.total = res.data.total;
              this.idlista = item.idlista;
              this.nomeLista = item.lista;
              this.verificaContinuacao();
              this.listaListar();
              this.carregando = false;
            })
            .catch(() =>{
              this.carregando = false;
              window.location.reload();
            });
      }, 300);
    },

    cancelaProgresso(){
      this.resetaCache();
      this.cancelaInclucao();
      this.listaProdutosSelecionados={lista:[]}
      this.dialog=false;
    },

    buscaProduto(pg, tamanho){
      this.pgSelecionada2 = pg;
      this.carregando = true;
      try {
        return axios
            .post(`${this.backendUrl}etiqueta/busca/produtos`, {
              busca: this.codigoDeBarras,
              idempresa: 1,
              limit: this.pgLimitInt2,
              offset: this.pgLimitInt2 * (pg-1),
              flagestoque: !this.busca.flagestoque
            })
            .then((res) => {
              if (res.data.total > 0){
                this.produtos.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
                  const compositeKey = `${v.idsubproduto}-${(v.preco - v.precopromocao).toFixed(2)}`;
                  const selectedProduto = this.listaProdutosSelecionados.lista.find(u => u.compositeKey === compositeKey);
                  const quantidade = selectedProduto ? selectedProduto.quantidade : 1;
                  return {
                    ...v,
                    compositeKey: compositeKey,
                    quantidade: quantidade,
                  };
                });
                this.produtos.total = res.data.total;
                if(tamanho==='P'){
                  this.dialogDetalhes=true;
                } else {
                  this.dialogDetalhes=false;
                }
              } else {
                this.snackbar2.text = 'Produto não encontrado, por favor verifique o produto informado e o filtro de estoque'
                this.snackbar2.color = 'error';
                this.snackbar2.status = true;
              }
              this.carregando = false;
            })
            .catch(() =>{
              this.carregando = false;
              window.location.reload();
            });
      } catch (error) {
        console.error("Erro ao consultar a API:", error);
      }
    },

    verificaQuantidade(produto) {
      if (produto.quantidade <= 0) {
        this.removerItem(produto.compositeKey);
      }
    },

    incrementaQuantidade(dialog,produto) {
      if (dialog==='detalhes'){
        const itemLista = this.listaProdutosSelecionados.lista.find(item => item.compositeKey === produto.compositeKey);
        if (itemLista) {
          itemLista.quantidade += 1;
        }
        produto.quantidade++;
      } else {
        const itemProdutos = this.produtos.lista.find(item => item.compositeKey === produto.compositeKey);
        if (itemProdutos) {
          itemProdutos.quantidade = produto.quantidade + 1;
        }
        produto.quantidade++;
      }
      this.salvaCache();
    },
    decrementaQuantidade(dialog,produto) {
      if (dialog==='detalhes'){
        const itemLista = this.listaProdutosSelecionados.lista.find(item => item.compositeKey === produto.compositeKey);
        if (itemLista) {
          itemLista.quantidade -= 1;
        }
        produto.quantidade--;
      } else {
        const itemProdutos = this.produtos.lista.find(item => item.compositeKey === produto.compositeKey);
        if (itemProdutos) {
          itemProdutos.quantidade -= 1;
        }
        produto.quantidade--;
      }
      this.$forceUpdate();
      this.verificaQuantidade(produto);  // Verifica após decremento
      this.salvaCache();
    },

    addItem(produto) {
      if (this.listaProdutosSelecionados.lista.length <= 149) {
        this.snackbar.text = 'Produto adicionado com sucesso!'
        this.snackbar.color = 'success';
        this.snackbar.status = true;
        produto.quantidade = 1;
        this.listaProdutosSelecionados.lista.push(JSON.parse(JSON.stringify(produto)));
        if ( this.produtos.total === 1 ) {
          setTimeout(() => {
            this.dialogDetalhes = false;
            this.cameraMode='auto';
          }, 1200);
        }
        this.salvaCache();
      } else {
        this.snackbar2.text = 'Produto não adicionado. Limite de 150 itens atingido!'
        this.snackbar2.color = 'error';
        this.snackbar2.status = true;

        this.snackbar.text = 'Limite de 150 itens atingido!'
        this.snackbar.color = 'error';
        this.snackbar.status = true;
      }
    },
    removerItem(itemId) {
      this.snackbar.text = 'Produto removido com sucesso!'
      this.snackbar.color = 'success';
      this.snackbar.status = true;

      this.snackbar4.text = 'Produto removido com sucesso!'
      this.snackbar4.color = 'success';
      this.snackbar4.status = true;
      if ( this.listaProdutosSelecionados.lista.length === 1 ){
        setTimeout(() => {
          this.dialogSelecionados = false;
        }, 1200);
      }
      if ( this.produtos.total === 1 ){
        setTimeout(() => {
          this.dialogDetalhes = false;
        }, 1200);
      }
      this.listaProdutosSelecionados.lista = this.listaProdutosSelecionados.lista.filter(item => item.compositeKey !== itemId);
      this.salvaCache();
    },

    async onDecode(decodedString) {
      console.log('Código EAN-13 detectado:', decodedString);
      if (!this.detectedCodes.includes(decodedString)) {
        try {
          return axios
              .post(`https://api.comercialivaipora.com.br/etiqueta/busca/produtos`, {
                busca: decodedString,
                idempresa: 1,
                limit: 100,
                offset: 0,
                flagestoque: false,
              })
              .then((res) => {
                if (res.data.total > 0) {
                  this.cameraMode='off';
                  this.produtos.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
                    const compositeKey = `${v.idsubproduto}-${(v.preco - v.precopromocao).toFixed(2)}`;
                    const selectedProduto = this.listaProdutosSelecionados.lista.find(u => u.compositeKey === compositeKey);
                    const quantidade = selectedProduto ? selectedProduto.quantidade : 1;
                    return {
                      ...v,
                      quantidade: quantidade,
                      compositeKey: compositeKey,
                    };
                  });
                  this.produtos.total = res.data.total;
                  this.dialogDetalhes = true;
                  if (this.addAutomatico && this.produtos.total === 1) {
                    const itemProdutos = this.listaProdutosSelecionados.lista.find(item => item.compositeKey === this.produtos.lista[0].compositeKey);
                    if (itemProdutos) {
                      itemProdutos.quantidade += 1;
                    } else {
                      this.addItem(this.produtos.lista)
                    }
                  }
                } else {
                  this.detectedCodes.push(decodedString);
                }
              })
              .catch(() => {
                window.location.reload();
              });
        } catch (error) {
          console.error("Erro ao consultar a API:", error);
        }
      } else {
        console.log('Código já detectado anteriormente:', decodedString);
      }
    },
    addNumero(number) {
      this.codigoDeBarras += number.toString();
    },
    deletaUltimo() {
      // Remove o último caractere do código de barras
      this.codigoDeBarras = this.codigoDeBarras.slice(0, -1);
    },
    startLongPress() {
      this.longPressTimeout = setTimeout(() => {
        this.codigoDeBarras = '';  // Função chamada após clique longo
      }, 500); // Define o tempo para considerar um "clique longo"
    },
    cancelLongPress() {
      clearTimeout(this.longPressTimeout);
    },

    cancelaInclucao() {
      this.dialogConfirmaDesfazimento = false;
      this.dialogConfirmaDelecao = false;
      this.concluir=false;
      this.dialogDetalhes = false;
      this.dialogSelecionados = false;
      this.dialogSelecionadosPc = false;
      this.produtos = {lista: []};
    },

    customTrack(detectedCodes, ctx) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      detectedCodes.forEach((code) => {
        const {x, y, width, height} = code.boundingBox;

        // Desenhar o contorno ao redor do código detectado
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 1;
        ctx.strokeRect(x, y, width, height);
      });
    },

    listaProdutosImprimir(){
      this.carregando = true;
      this.dialogRetorno= {status: false};
        return axios
          .post(`${this.backendUrl}etiqueta/lista/produtos/listar`, {
            idlista: this.listaTemp.idlista,
            flagimpressao: 'T',
          })
          .then((res) => {
            this.listaProdutosImpressao.lista = res.data.lista == null ? [] : res.data.lista.map((v) => {
              const compositeKey = `${v.idsubproduto}-${(v.preco - v.precopromocao).toFixed(2)}`;
              // const selectedProduto = this.listaProdutosSelecionados.lista.find(u => u.compositeKey === compositeKey);
              // const quantidade = selectedProduto ? selectedProduto.quantidade : 1;
              return {
                ...v,
                // quantidade: quantidade,
                compositeKey: compositeKey,
              };
            });
            this.listaProdutosImpressao.total = res.data.total;
            this.carregando = false;
            this.listaTemp={};
          })
          .catch(() =>{
            this.carregando = false;
            window.location.reload();
          });
    },

    async geraEtiquetas() {
      this.dialogConfirmaImpressao=false;
      await this.listaProdutosImprimir();
      this.carregandoPdfMensagem = `Aguarde, buscando dados de ${this.listaProdutosImpressao.total} produtos`;
      this.carregandoPdf = true;
      // console.log(this.listaProdutosImpressao)

      const dados = this.listaProdutosImpressao.lista.flatMap(item => Array(item.quantidade).fill(item));

      // console.log(dados)
      let agora = new Date();
      let ano = agora.getFullYear();
      let mes = ('0' + (agora.getMonth() + 1)).slice(-2); // +1 porque o mês começa do zero
      let dia = ('0' + agora.getDate()).slice(-2);

      for (let j = 0; j < Math.ceil(dados.length / 672); j++) {
        var linha = 1;
        const startIndex = j * 672;
        const endIndex = Math.min(startIndex + 672, dados.length);

        var htmlContent = `<!DOCTYPE html>
                          <html>
                          <head>
                              <title>Etiquetas</title>
                              <meta charset="UTF-8">
                              <meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1">
                              <style>
                                  body {
                                      font-family: Arial, sans-serif;
                                  }

                                  .etiquetas-table {
                                      margin: 0 3mm 0 3mm !important;
                                      width: 204mm;
                                      border-collapse: separate;
                                      border-spacing: 6px 3px; /* Espaço entre colunas e linhas */
                                  }

                                  .etiqueta {
                                      border: 1px solid black !important;
                                      width: 33.33%;
                                      height: 100%;
                                      box-sizing: border-box;
                                  }

                                  .etiqueta table {
                                      width: 100%;
                                      border-collapse: collapse;
                                  }

                                  .etiqueta table td {
                                      padding: 8px;
                                      text-align: center;
                                  }
                                  .nome_produto {
                                      height:35px !important;
                                      text-transform: uppercase;
                                      text-align: left !important;
                                      font-size: 0.6em !important;
                                      padding: 2px !important;
                                  }
                                  .preco {
                                      width: 80px;
                                      text-align: left !important;
                                      padding: 0px !important;
                                  }
                                  .preco .titulo {
                                      font-size: 0.6em !important;
                                      font-weight: bold;
                                  }
                                  .preco .valor{
                                      font-size: 0.9em;
                                      font-weight: bold;
                                  }
                                  .preco_promocao {
                                      width: 80px;
                                      text-align: left !important;
                                      padding: 0px !important;
                                      vertical-align: top !important;
                                  }
                                  .preco_promocao .titulo {
                                      font-size: 0.6em !important;
                                      font-weight: bold;
                                      padding: 0px !important;
                                  }
                                  .preco_promocao .valor{
                                      font-size: 0.9em;
                                      font-weight: bold;
                                      padding: 0px !important;
                                  }
                                  .preco_promocao .titulo_promocao {
                                      font-size: 0.5em !important;
                                      font-weight: bold;
                                      padding: 0px !important;
                                  }
                                  .preco_promocao .valor_promocao{
                                      font-size: 0.7em;
                                      font-weight: normal;
                                      padding: 0px !important;
                                  }
                                  .codigo_barras_imagem {
                                      padding: 0px 2px !important;
                                  }

                                  .codigo_barras_imagem .imagem {
                                      width:120px;
                                      height:58px;
                                  }

                                  .codigo_barras_numero {
                                      font-size: 0.7em !important;
                                      padding: 0px !important;
                                  }

                                  .data {
                                      font-size: 0.4em !important;
                                      text-align: left !important;
                                      padding-top: 0px !important;
                                  }

                                  .numero {
                                      padding: 0px !important;
                                  }

                                  .numero div {
                                      display: inline-block !important;
                                      font-size: 0.6em !important;
                                      text-align: center !important;
                                      width:8px;
                                      padding: 0px !important;
                                      word-wrap: break-word !important;
                                  }

                                  .numero_titulo {
                                      text-align: right !important;
                                      vertical-align: bottom !important;
                                      font-size: 0.5em !important;
                                      width:1px;
                                      padding: 0px !important;
                                      padding-right: 2px !important;
                                  }

                                  .embalagem {
                                      vertical-align: top !important;
                                      font-size: 0.8em !important;
                                      width: 1px;
                                      padding: 0px !important;
                                      padding-top: 5px !important;
                                      padding-right: 5px !important;
                                      font-weight: bold;
                                  }

                                  .page-break {
                                      page-break-before: always;
                                      break-before: page;
                                  }
                              </style>
                          </head>
                          <body>
                          <div style="height: 4mm">&nbsp;</div>`;
        for (let i = startIndex; (i < endIndex); i += 3) {
          if (i + 2 < endIndex) {
            htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                                ${dados[i].precopromocao > 0 ?
                `<td class="preco_promocao" rowspan="3">
                                                    <span class="titulo_promocao"> De: </span><br/>
                                                    <span class="valor_promocao">${parseFloat(dados[i].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span><br/>
                                                    <span class="titulo"> Por: </span><br/>
                                                    <span class="valor">${parseFloat(dados[i].precopromocao).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span>
                                                </td>` :
                `<td class="preco" rowspan="3"><span class="titulo"> Preço á Vista: </span> <br/><span class="valor">${parseFloat(dados[i].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span> </td>` }
                                              <td class="embalagem" rowspan="3">${dados[i].embalagemsaida}</td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+1].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+1].base64}" />
                                              </td>
                                                ${dados[i+1].precopromocao > 0 ?
                `<td class="preco_promocao" rowspan="3">
                                                    <span class="titulo_promocao"> De: </span><br/>
                                                    <span class="valor_promocao">${parseFloat(dados[i+1].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span><br/>
                                                    <span class="titulo"> Por: </span><br/>
                                                    <span class="valor">${parseFloat(dados[i+1].precopromocao).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span>
                                                </td>` :
                `<td class="preco" rowspan="3"><span class="titulo"> Preço á Vista: </span> <br/><span class="valor">${parseFloat(dados[i+1].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span> </td>` }
                                              <td class="embalagem" rowspan="3">${dados[i+1].embalagemsaida}</td>
                                              <td class="numero" rowspan="3"><div>${dados[i+1].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+1].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+2].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                                                                    <tr>
                                              <td colspan="2" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+2].base64}" />
                                              </td>
                                                ${dados[i+2].precopromocao > 0 ?
                `<td class="preco_promocao" rowspan="3">
                                                    <span class="titulo_promocao"> De: </span><br/>
                                                    <span class="valor_promocao">${parseFloat(dados[i+2].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span><br/>
                                                    <span class="titulo"> Por: </span><br/>
                                                    <span class="valor">${parseFloat(dados[i+2].precopromocao).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span>
                                                </td>` :
                `<td class="preco" rowspan="3"><span class="titulo"> Preço á Vista: </span> <br/><span class="valor">${parseFloat(dados[i+2].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span> </td>` }
                                              <td class="embalagem" rowspan="3">${dados[i+2].embalagemsaida}</td>
                                              <td class="numero" rowspan="3"><div>${dados[i+2].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+2].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
          } else if (i + 1 < endIndex) {
            htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                                ${dados[i].precopromocao > 0 ?
                `<td class="preco_promocao" rowspan="3">
                                                    <span class="titulo_promocao"> De: </span><br/>
                                                    <span class="valor_promocao">${parseFloat(dados[i].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span><br/>
                                                    <span class="titulo"> Por: </span><br/>
                                                    <span class="valor">${parseFloat(dados[i].precopromocao).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span>
                                                </td>` :
                `<td class="preco" rowspan="3"><span class="titulo"> Preço á Vista: </span> <br/><span class="valor">${parseFloat(dados[i].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span> </td>` }
                                              <td class="embalagem" rowspan="3">${dados[i].embalagemsaida}</td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i+1].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                                                                    <tr>
                                              <td colspan="2" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i+1].base64}" />
                                              </td>
                                                ${dados[i+1].precopromocao > 0 ?
                `<td class="preco_promocao" rowspan="3">
                                                    <span class="titulo_promocao"> De: </span><br/>
                                                    <span class="valor_promocao">${parseFloat(dados[i+1].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span><br/>
                                                    <span class="titulo"> Por: </span><br/>
                                                    <span class="valor">${parseFloat(dados[i+1].precopromocao).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span>
                                                </td>` :
                `<td class="preco" rowspan="3"><span class="titulo"> Preço á Vista: </span> <br/><span class="valor">${parseFloat(dados[i+1].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span> </td>` }
                                              <td class="embalagem" rowspan="3">${dados[i+1].embalagemsaida}</td>
                                              <td class="numero" rowspan="3"><div>${dados[i+1].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i+1].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td><div>&nbsp;</div></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
          } else {
            htmlContent += `
                  <div>
                      <table class="etiquetas-table">
                          <tbody>
                              <tr>
                                  <td class="etiqueta">
                                      <table>
                                          <tr>
                                              <td colspan="3" class="nome_produto">${dados[i].produto}</td>
                                              <td colspan="2" class="numero_titulo">Cód.</td>
                                          </tr>
                                                                                    <tr>
                                              <td colspan="2" class="codigo_barras_imagem">
                                                  <img class="imagem" src="data:image/png;base64,${dados[i].base64}" />
                                              </td>
                                                ${dados[i].precopromocao > 0 ?
                `<td class="preco_promocao" rowspan="3">
                                                    <span class="titulo_promocao"> De: </span><br/>
                                                    <span class="valor_promocao">${parseFloat(dados[i].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span><br/>
                                                    <span class="titulo"> Por: </span><br/>
                                                    <span class="valor">${parseFloat(dados[i].precopromocao).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span>
                                                </td>` :
                `<td class="preco" rowspan="3"><span class="titulo"> Preço á Vista: </span> <br/><span class="valor">${parseFloat(dados[i].preco).toLocaleString("pt-br", {style: "currency",currency:"BRL",})} </span> </td>` }
                                              <td class="embalagem" rowspan="3">${dados[i].embalagemsaida}</td>
                                              <td class="numero" rowspan="3"><div>${dados[i].idsubproduto}</div></td>
                                          </tr>
                                          <tr>
                                          <tr>
                                              <td colspan="2" class="codigo_barras_numero">
                                                  ${dados[i].codigobarra}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td colspan="2" class="data">
                                                  ${ano}${mes}${dia}
                                              </td>
                                          </tr>
                                      </table>
                                  </td>
                                  <td><div>&nbsp;</div></td>
                                  <td><div>&nbsp;</div></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>`;
          }
          if ( (linha) != 1 && 0 == ((linha) % 8) && (i+3) < endIndex){
            htmlContent += `<div class="page-break">&nbsp;</div>
                                <div style="height: 3mm">&nbsp;</div>`;
          }
          linha++;
        }
        htmlContent+= `</body> </html>`;
        this.carregandoPdfMensagem = `Gerando arquivo PDF, parte ${j+1} de ${Math.ceil(dados.length / 672)}`;
        await this.geraArquivoFinal(htmlContent, (j+1),Math.ceil(dados.length / 672), agora);
        if ((j+1) == Math.ceil(dados.length / 672)) {
          this.carregandoPdf = false;
        }
      }
    },

    async geraArquivoFinal(htmlContent, i, f, agora){

      let dia = ('0' + agora.getDay()).slice(-2);
      let hora = ('0' + agora.getHours()).slice(-2);
      let minuto = ('0' + agora.getMinutes()).slice(-2);
      let segundos = ('0' + agora.getSeconds()).slice(-2);

      var nomeArquivo = `Etiquetas_parte_${i}_de_${f} (${dia}${hora}${minuto}${segundos}).pdf`;

      var opt = {
        margin: 0,
        filename: nomeArquivo,
        image: {type: "jpeg", quality: 1},
        html2canvas: {dpi: 192, letterRendering: true, scale: 2},
        jsPDF: {
          unit: "pt",
          format: "a4",
          orientation: "portrait",
        },
      };

      await html2pdf().from(htmlContent).set(opt).toPdf().get('pdf').save();
    },

    async init(){
      await this.listaListar(0);
    }
  },
  mounted() {
    this.dynamicPlaceholder = window.innerWidth >= 600 ? 'Informe a descrição, códido de barras ou códido interno do produto' : 'Descrição, cód. de barras ou cód. interno';
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
::v-deep .v-data-table__mobile-table-row td {
  min-height: 30px !important; /* Ajuste a altura conforme necessário */
  font-size: 0.8em !important;
}

::v-deep .v-data-table__mobile-table-row td {
  min-height: 30px !important; /* Ajuste a altura conforme necessário */
  font-size: 0.8em !important;
}

::v-deep .custom-text-field .v-input__prepend-inner {
  margin-right: 6px !important; /* Ajuste o valor para o espaçamento desejado */
}

::v-deep .custom-text-field:is(.v-input--is-label-active, .v-input--is-focused) .v-label {
  margin-left: 31px !important; /* Adiciona espaço à label */
}

::v-deep .custom-text-field.v-text-field--outlined fieldset {
  padding-left: 45px !important; /* Ajuste conforme necessário */
}

.scanner {
  position: relative;
  height: 100%;
  width: 100%;
  border: 12px #e3e3e3 solid !important;
  border-radius: 6px;
}

.corner-rectangle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 220px;
  height: 80px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* Estilo das vértices */
.corner {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid white;
}

/* Vértices superiores */
.top-left {
  top: -3px;
  left: -3px;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: -3px;
  right: -3px;
  border-left: none;
  border-bottom: none;
}

/* Vértices inferiores */
.bottom-left {
  bottom: -3px;
  left: -3px;
  border-right: none;
  border-top: none;
}

.bottom-right {
  bottom: -3px;
  right: -3px;
  border-left: none;
  border-top: none;
}

.laser-line {
  position: absolute;
  top: 50%; /* Centraliza verticalmente */
  left: 0;
  width: 100%;
  height: 1px;
  background-color: red;
  box-shadow: 0px 0px 8px red; /* Efeito de brilho na linha */
}

/* Estilo do botão Adicionar */
.add-button {
  position: absolute;
  bottom: 5px;
  left: 40px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 10;
}

.error {
  color: red;
  margin-top: 20px;
  font-weight: bold;
}

.teclado-row {
  display: flex;
  justify-content: space-between;
}

.teclado-row button {
  flex: 1;
  margin: 5px;
  padding: 20px; /* Aumenta o tamanho das teclas */
  font-size: 1.5rem; /* Aumenta o tamanho do texto */
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.teclado-row button:hover {
  background-color: #e0e0e0;
}

.teclado-row v-icon {
  font-size: 1.5rem; /* Tamanho dos ícones */
}

::v-deep .dialog-detalhes {
  margin: 5px 14px 50px 14px !important;
  box-shadow: none !important;
  scrollbar-width: thin; /* Largura da barra de rolagem */
  scrollbar-color: #f1f1f1 #023449; /* Cor da barra de rolagem e da trilha */
}

/* Estilizando a barra de rolagem para navegadores WebKit */
::v-deep .dialog-detalhes::-webkit-scrollbar {
  width: 8px; /* Largura da barra de rolagem */
}

::v-deep .dialog-detalhes::-webkit-scrollbar-track {
  background: #f1f1f1; /* Cor de fundo da trilha */
}

::v-deep .dialog-detalhes::-webkit-scrollbar-thumb {
  background-color: #f1f1f1; /* Cor da barra de rolagem */
  border-radius: 10px; /* Bordas arredondadas */
  border: 2px solid #023449; /* Espaço ao redor da barra de rolagem */
}
</style>
